<template>
  <keep-alive>
    <component v-bind:is="currentTab" class="tab"></component>
  </keep-alive>
</template>

<script>
import ContactList from './ContactList.vue'
import ConversationList from './ConversationList.vue'

export default {
  name: 'SidebarContent',
  props: ['currentTab'],
  components: {
    ContactList,
    ConversationList
  }
}
</script>

<style lang="scss"></style>
