<template>
  <fragment>
    <div id="sidepanel" v-if="!isLoading">
      <div id="contacts" v-bar>
        <ul>
          <p v-if="sortedContacts.length > 0">{{ strings.contactListHeaderText }}</p>
          <p v-else>{{ strings.contactListHeaderEmptyText }}</p>
          <h3>{{ strings.contactListLabel }} &nbsp; ({{ sortedContacts.length }})</h3>
          <ContactListItem
            v-for="contact in sortedContacts"
            v-bind:key="contact.id"
            :contact="contact"
          />
        </ul>
      </div>
      <div id="guest-alert">
        <div class="alert alert--warning">
          <p>{{ strings.guestAlertText }}</p>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'

import ContactListItem from '../components/ContactListItem.vue'

export default {
  name: 'Login',
  methods: {
    ...mapActions(['guestLogin'])
  },
  computed: {
    ...mapState({
      isLoading: state => state.vars.isLoading
    }),
    sortedContacts () {
      let sortFunction = contact => contact.username
      return orderBy(this.contacts, sortFunction, ['desc'])
    },
    ...mapGetters(['contacts', 'strings'])
  },
  created () {
    this.debouncedGuestLogin = debounce(() => this.guestLogin(), 2000, {
      leading: true,
      trailing: false
    })
  },
  components: {
    ContactListItem
  }
}
</script>

<style lang="scss">
</style>
