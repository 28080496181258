import debounce from 'lodash/debounce'

let debounceQueue = function (callback, wait = 0, options = {}) {
  if (typeof callback !== 'function') {
    throw new TypeError('Expected a function')
  }

  let debouncedCallback = debounce(
    data => {
      callback(data)
      queue = []
    },
    wait,
    options
  )
  let queue = []

  let debouncedQueued = function (...args) {
    queue.push(...args)
    debouncedCallback(queue)
  }

  return debouncedQueued
}

export default debounceQueue
