<template>
  <div class="message-input">
    <div class="wrap">
      <BaseAutogrowTextarea>
        <textarea
          v-model="messageText"
          @keydown.exact.enter.prevent
          @keyup.exact.enter="send"
          placeholder="Write your message... (Enter to send, Shift+Enter for new line)"
        ></textarea>
      </BaseAutogrowTextarea>
      <button class="submit" :disabled="!canSend" @click="send">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import uniq from 'lodash/uniq'

import BaseAutogrowTextarea from './Base/BaseAutogrowTextarea'

export default {
  name: 'MessageInput',
  props: ['conversation'],
  data: () => ({
    messageText: ''
  }),
  computed: {
    isSpam: function () {
      if (
        !this.preventSpamAfterNthMessage ||
        this.privileges.canIgnoreAntiSpam ||
        this.conversation.createdBy.id !== this.userId
      ) {
        return false
      }
      if (this.conversation.messages.length < this.preventSpamAfterNthMessage.number) return false
      if (this.conversation.messages.length >= this.preventSpamAfterNthMessage.number) {
        let users = uniq(this.conversation.messages.map(m => m.sender.id))
        if (users.length === 1) {
          return users[0] === this.userId
        }
      }
      return false
    },
    canSend: function () {
      return this.messageText !== undefined && this.messageText.trim().length > 0 && !this.isSpam
    },
    ...mapState({
      userId: state => state.vars.userId,
      preventSpamAfterNthMessage: state => state.vars.config.preventSpamAfterNthMessage
    }),
    ...mapGetters(['privileges'])
  },
  methods: {
    send: function () {
      if (this.canSend) {
        let data = { conversationId: this.conversation.id, text: this.messageText.trim() }
        this.sendMessage(data)
        this.messageText = ''
        this.$el.querySelector('textarea').dispatchEvent(new Event('resetHeight'))
      }
    },
    ...mapActions(['sendMessage'])
  },
  components: {
    BaseAutogrowTextarea
  }
}
</script>

<style lang="scss"></style>
