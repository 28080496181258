<template>
  <div id="conversations" v-bar>
    <ul>
      <h2>{{ strings.conversationListLabel }}</h2>
      <p v-if="sortedConversations.length > 0">{{ strings.conversationListHeaderText }}</p>
      <p v-else>{{ strings.conversationListHeaderEmptyText }}</p>
      <ConversationListItem
        v-for="conversation in sortedConversations"
        v-bind:key="conversation.id"
        :conversation="conversation"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import moment from 'moment'
import orderBy from 'lodash/orderBy'

import ConversationListItem from './ConversationListItem'

export default {
  name: 'ConversationList',
  computed: {
    sortedConversations () {
      let sortFunction = conversation =>
        conversation.messages.length > 0 ? moment(conversation.messages.slice(-1)[0].ts) : moment(this.createdAt)
      let activeConversations = this.conversations.filter(conversation => conversation.closedAt == null)
      return orderBy(activeConversations, sortFunction, ['desc'])
    },
    ...mapGetters(['conversations', 'strings'])
  },
  components: {
    ConversationListItem
  }
}
</script>

<style lang="scss" scoped></style>
