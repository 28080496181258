<template>
  <div class="content">
    <div class="conversation-profile">
      <div class="contact-profile">
        <img :src="getUserAvatar(partner)" />
        <p>{{ getUserDisplayName(partner) }}</p>
      </div>
      <div class="menu">
        <button @click="closeConversation(conversation.id)" v-if="conversation.closedAt == null">
          <font-awesome-icon icon="times-circle" />Mark as resolved
        </button>
        <button @click="hideConversation()" v-if="conversation.closedAt != null">
          <font-awesome-icon icon="check-circle" />Close
        </button>
      </div>
    </div>
    <div class="messages" v-bar>
      <ul ref="messageList">
        <div class="alert-small">
          <p>This conversation started at {{ formatTimestamp(conversation.createdAt) }} by {{ getUserDisplayName(conversation.createdBy) }}</p>
        </div>
        <div class="alert-big" v-if="showPreventSpamAlert">
          <p>{{ preventSpamAfterNthMessage.message }}</p>
        </div>
        <MessageListItem
          v-for="message in conversation.messages"
          v-bind:key="message.id"
          :message="message"
          :sender="sender(message)"
        />
        <div class="alert-small" v-if="conversation.closedAt !== null">
          <p>This conversation was closed at {{ formatTimestamp(conversation.closedAt) }} by {{ getUserDisplayName(conversation.closedBy) }}</p>
        </div>
      </ul>
    </div>
    <MessageInput v-if="conversation.closedAt == null" :conversation="conversation" />
  </div>
</template>

<script>
import moment from 'moment'

import uniq from 'lodash/uniq'
import findLastIndex from 'lodash/findLastIndex'

import { mapActions, mapGetters, mapState } from 'vuex'

import MessageListItem from './MessageListItem.vue'
import MessageInput from './MessageInput.vue'

export default {
  name: 'ConversationContent',
  props: ['conversation'],
  computed: {
    partner () {
      return this.partners[0]
    },
    partners () {
      return this.conversation.users.filter(user => user.id !== this.userId)
    },
    lastMessageIndex () {
      return this.conversation.messages.length - 1
    },
    lastReadOrSentMessageIndex () {
      let lastSentIndex = findLastIndex(this.conversation.messages, m => m.sender.id === this.userId)
      let lastReadIndex = findLastIndex(this.conversation.messages, m =>
        m.readBy.map(rb => rb.id).includes(this.userId)
      )
      return Math.max(lastSentIndex, lastReadIndex)
    },
    showPreventSpamAlert () {
      if (
        !this.preventSpamAfterNthMessage ||
        this.privileges.canIgnoreAntiSpam ||
        this.conversation.createdBy.id !== this.userId
      ) {
        return false
      }
      if (this.conversation.messages.length <= this.preventSpamAfterNthMessage.number) {
        if (this.conversation.messages.length === 0) {
          return true
        }
        let users = uniq(this.conversation.messages.map(m => m.sender.id))
        if (users.length === 1) {
          return users[0] === this.userId
        }
      }
      return false
    },
    ...mapState({
      userId: state => state.vars.userId,
      preventSpamAfterNthMessage: state => state.vars.config.preventSpamAfterNthMessage
    }),
    ...mapGetters(['getUserAvatar', 'getUserDisplayName', 'privileges'])
  },
  methods: {
    formatTimestamp (timestamp) {
      return moment(timestamp).format('LLL')
    },
    sender (message) {
      return this.conversation.users.find(user => user.id === message.sender.id)
    },
    ...mapActions(['closeConversation', 'hideConversation'])
  },
  updated: function () {
    this.$nextTick(() => {
      if (this.lastMessageIndex - this.lastReadOrSentMessageIndex <= 1) {
        var ref = this.$refs.messageList
        ref.scrollTop = ref.scrollHeight
      }
    })
  },
  components: {
    MessageListItem,
    MessageInput
  }
}
</script>

<style lang="scss"></style>
