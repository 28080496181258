<template>
  <div id="profile">
    <div class="wrap">
      <img
        id="profile-img"
        :src="getUserAvatar(user)"
        v-tooltip="{ content: getUserDisplayName(user), boundariesElement: 'html', placement: 'right' }"
      />
      <p>{{ getUserDisplayName(user) }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfile',
  props: ['user'],
  computed: {
    ...mapGetters(['getUserAvatar', 'getUserDisplayName'])
  }
}
</script>

<style lang='scss' scoped></style>
