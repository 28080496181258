<template>
  <fragment>
    <div id="sidepanel" v-if="!isLoading">
      <div class="logo">
        <img :src="localConfig.logoUrl" />
      </div>
      <UserProfile v-if="userId" :user="userById(userId)" />
      <SidebarContent :currentTab="currentSidebarTab" />
      <SidebarTabList :currentTab="currentSidebarTab" />
    </div>
    <template v-if="!isLoading">
      <ConversationContent
        v-for="conversation in conversations"
        v-show="conversation.id === currentConversationId"
        v-bind:key="conversation.id"
        :conversation="conversation"
      />
    </template>
  </fragment>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import UserProfile from '../components/UserProfile.vue'
import SidebarTabList from '../components/SidebarTabList.vue'
import SidebarContent from '../components/SidebarContent.vue'
import ConversationContent from '../components/ConversationContent.vue'

export default {
  name: 'Chat',
  computed: {
    ...mapState({
      localConfig: state => state.localConfig,
      currentConversationId: state => state.vars.currentConversationId,
      currentSidebarTab: state => state.vars.currentSidebarTab,
      userId: state => state.vars.userId,
      isLoading: state => state.vars.isLoading
    }),
    ...mapGetters(['contacts', 'conversations', 'userById', 'strings'])
  },
  components: {
    UserProfile,
    SidebarTabList,
    SidebarContent,
    ConversationContent
  }
}
</script>

<style lang="scss">
</style>
