import Vue from 'vue'
import App from './App.vue'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faComments,
  faUserFriends,
  faPaperPlane,
  faTimesCircle,
  faExclamationCircle,
  faCheckCircle,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import io from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import Loading from 'vue-loading-overlay'
import VTooltip from 'v-tooltip'
import Vuebar from 'vuebar'
import visibility from 'vue-visibility-change'
import VueNativeNotification from 'vue-native-notification'
import VueObserveVisibility from 'vue-observe-visibility'

import Fragment from 'vue-fragment'

library.add(
  faSearch,
  faComments,
  faUserFriends,
  faPaperPlane,
  faTimesCircle,
  faExclamationCircle,
  faCheckCircle,
  faArrowLeft,
  faArrowRight
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(
  new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: io(process.env.VUE_APP_SERVER_SOCKETIO_URL, {
      autoConnect: false
    }),
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'socket_'
    }
  })
)
Vue.use(Loading, {
  color: '#999',
  backgroundColor: '#00375a',
  opacity: 0.8,
  loader: 'dots'
})
Vue.use(VTooltip, { autoHide: false })
Vue.use(Vuebar)
Vue.use(visibility)
Vue.use(VueNativeNotification, { requestOnNotify: false })
Vue.use(VueObserveVisibility)
Vue.use(Fragment.Plugin)

Vue.config.devtools = process.env.VUE_APP_NODE_ENV !== 'production'
Vue.config.productionTip = process.env.VUE_APP_NODE_ENV !== 'production'

export default new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
