<template>
  <div v-if="sidebarTablistEnabled" id="sidebar-tablist">
    <button
      :class="{ active: isActive(tab) }"
      :title="tab.text"
      v-for="tab in tabs"
      v-bind:key="tab.name"
      v-on:click="selectSidebarTab(tab.name)"
    >
      <font-awesome-icon :icon="tab.icon" />
      <span>{{ tab.text }}</span>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SidebarTabList',
  props: ['currentTab'],
  computed: {
    sidebarTablistEnabled () {
      return this.privileges.canSelectContact && this.privileges.canSelectConversation
    },
    ...mapGetters(['strings', 'privileges']),
    tabs () {
      return [
        { name: 'ContactList', icon: 'user-friends', text: this.strings.contactListLabel },
        { name: 'ConversationList', icon: 'comments', text: this.strings.conversationListLabel }
      ]
    }
  },
  methods: {
    isActive (tab) {
      return tab === this.currentTab
    },
    ...mapMutations(['selectSidebarTab'])
  }
}
</script>

<style lang="scss"></style>
