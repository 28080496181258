<script>
export default {
  name: 'BaseAutogrowTextarea',
  data: () => ({
    originalHeight: null
  }),
  methods: {
    autoResize (event) {
      event.target.style.height = ''
      event.target.style.height = event.target.scrollHeight + 'px'
    },
    resetHeight (event) {
      event.target.style.height = this.originalHeight + 'px'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.originalHeight = this.$el.scrollHeight
      this.$el.setAttribute('style', 'height: ' + this.$el.scrollHeight + 'px;')
    })

    this.$el.addEventListener('input', this.autoResize)
    this.$el.addEventListener('resetHeight', this.resetHeight)
  },
  beforeDestroy () {
    this.$el.removeEventListener('input', this.autoResize)
    this.$el.removeEventListener('resetHeight', this.resetHeight)
  },
  render () {
    return this.$slots.default[0]
  }
}
</script>
