<template>
  <fragment>
    <div id="error-view" v-if="!isLoading">
      <div class="logo">
        <img :src="localConfig.logoUrl" />
      </div>
      <div class="error">
        <h1>Sorry.</h1>
        <p>There was an error with your authentication, try again.</p>
        <div class="alert alert--danger" v-for="error in errors" v-bind:key="error.message">
          <p>
            <strong>Error:</strong>
            {{ error.message }}
          </p>
        </div>
        <p>Automatically redirecting to the login page in {{ timeBeforeRedirect }}s</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Error',
  data: () => ({
    redirectTimer: null,
    updateRedirectTimer: null,
    timeBeforeRedirect: null
  }),
  computed: {
    ...mapState({
      localConfig: state => state.localConfig,
      isLoading: state => state.vars.isLoading,
      errors: state => state.arrays.errors
    })
  },
  created () {
    this.redirectTimer = new Timer(() => {
      clearTimeout(this.updateRedirectTimer)
      this.$store.dispatch('redirectToLogin')
    }, 5000)
    this.updateRedirectTimer = setInterval(() => {
      this.timeBeforeRedirect = Math.floor(this.redirectTimer.getTimeLeft() / 1000)
    }, 500)
  }
}

function Timer (callback, delay) {
  let id
  let started
  let remaining = delay
  let running

  this.start = function () {
    running = true
    started = new Date()
    id = setTimeout(callback, remaining)
  }

  this.pause = function () {
    running = false
    clearTimeout(id)
    remaining -= new Date() - started
  }

  this.getTimeLeft = function () {
    if (running) {
      this.pause()
      this.start()
    }

    return remaining
  }

  this.getStateRunning = function () {
    return running
  }

  this.start()
}
</script>

<style lang="scss">
</style>
