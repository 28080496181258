<template>
  <li class="accordion">
    <div
      class="accordion__arrow"
      :class="{'accordion__arrow--open' : isOpen}"
      @click="openItem(item)"
    ></div>
    <div class="accordion__header" @click="openItem(item)">
      <slot name="header"/>
    </div>
    <transition v-on:enter="enter" v-on:leave="leave">
      <div v-show="isOpen" class="accordion__item">
        <slot name="content"/>
      </div>
    </transition>
  </li>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
  name: 'BaseAccordionItem',
  props: ['item'],
  data: () => ({
    isOpen: false
  }),
  methods: {
    openItem: function (item) {
      this.isOpen = !this.isOpen
    },
    setClass: function (item) {
      if (this.isOpen === true) {
        return 'open'
      }
      return 'close'
    },
    enter: function (el, done) {
      Velocity(el, 'slideDown', { duration: 400, easing: 'easeInBack' }, { complete: done })
    },
    leave: function (el, done) {
      Velocity(el, 'slideUp', { duration: 400, easing: 'easeInBack' }, { complete: done })
    }
  }
}
</script>

<style lang="scss">
.accordion {
  li {
    list-style: none;
    cursor: pointer;
  }

  .accordion__header {
    margin-left: 25px;
  }

  .accordion__arrow {
    width: 10px;
    height: 10px;
    transition: all 0.3s;
    padding-bottom: 0px;
    margin: 0 10px;
    margin-bottom: -36px;
    margin-top: 10px;
  }

  .accordion__arrow:after,
  .accordion__arrow:before {
    border: solid transparent;
    content: " ";
    position: absolute;
  }

  .accordion__arrow:after {
    border-width: 5px;
  }
  .accordion__arrow:before {
    border-left-color: #fff;
    border-width: 5px;
  }

  .accordion__arrow--open {
    transform: rotateZ(90deg);
    transform-origin: 50% 50%;
  }
}
</style>
