<template>
  <li class="contact" :class="{ readonly: isReadOnly }" @click="debouncedSelectContact">
    <div class="wrap">
      <div
        class="contact-profile"
        v-tooltip="{ content: getUserDisplayName(contact), boundariesElement: 'html', placement: 'right' }"
      >
        <span class="contact-status"></span>
        <img :src="getUserAvatar(contact)" />
      </div>
      <div class="meta">
        <p class="name">{{ getUserDisplayName(contact) }}</p>
        <p class="preview" v-if="contactNumActiveConversation >= 0">{{ previewText }}</p>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import debounce from 'lodash/debounce'

export default {
  name: 'ContactListItem',
  props: {
    contact: Object,
    isReadOnly: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    contactNumActiveConversation () {
      return this.getNumActiveConversationsByUserId(this.contact.id)
    },
    previewText () {
      let [prefix, subfix] = this.lobbyById(this.contact.lobby).strings.contactListItemPreview
      return `${prefix} ${this.contactNumActiveConversation} ${subfix}`
    },
    ...mapGetters(['getUserAvatar', 'getUserDisplayName', 'lobbyById', 'getNumActiveConversationsByUserId'])
  },
  methods: {
    ...mapActions(['selectContact'])
  },
  created () {
    this.debouncedSelectContact = debounce(
      () => {
        if (!this.isReadOnly) this.selectContact(this.contact)
      },
      2000,
      {
        leading: true,
        trailing: false
      }
    )
  }
}
</script>

<style lang="scss" scoped></style>
