let until = function (fn, pollingInterval = 200) {
  const poll = resolve => {
    if (fn()) resolve()
    else setTimeout(_ => poll(resolve), pollingInterval)
  }

  return new Promise(poll)
}

export default until
