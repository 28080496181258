<template>
  <li :class="messageClass" v-observe-visibility="{ callback: visibilityChanged, once: true }">
    <img :src="getUserAvatar(sender)" />
    <p v-tooltip="{ content: messageTimestamp, placement: 'bottom' }">{{ this.message.text }}</p>
  </li>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import moment from 'moment'

export default {
  name: 'MessageListItem',
  props: ['message', 'sender'],
  computed: {
    messageClass () {
      return this.message.sender.id === this.userId ? 'sent' : 'replies'
    },
    messageTimestamp () {
      return `${this.getUserDisplayName(this.message.sender)} sent this message at ${moment(this.message.ts).format(
        'lll'
      )}`
    },
    ...mapState({
      userId: state => state.vars.userId
    }),
    ...mapGetters(['getUserAvatar', 'getUserDisplayName'])
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        this.message.sender.id !== this.userId &&
        !this.message.readBy.map(rb => rb.id).includes(this.userId)
      ) {
        this.sendReadMessage(this.message.id)
      }
    },
    ...mapActions(['sendReadMessage'])
  }
}
</script>

<style lang="scss"></style>
