import Vue from 'vue'
import Vuex from 'vuex'
import vm from './main'

import axios from 'axios'
import cors from 'cors'
import Cookie from 'js-cookie'
import moment from 'moment'
import idx from 'idx'
import intersection from 'lodash/intersection'
import debounceQueue from './utils/debounceQueue'
import until from './utils/until'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    models: {
      accounts: [],
      conversations: [],
      messages: [],
      persons: [],
      usersActiveConversations: []
    },

    arrays: {
      contactsIds: [],
      errors: []
    },

    vars: {
      currentView: 'login',
      jwt: undefined,
      userId: undefined,
      currentConversationId: undefined,
      currentSidebarTab: undefined,
      isConnectedOrConnecting: false,
      isLoading: true,
      chatInitialized: false,
      sidebarTablistEnabled: false,
      isPageHidden: false
    },

    localConfig: {
      logoUrl: process.env.VUE_APP_SITE_LOGO_URL
    }

  },
  mutations: {
    changeView (state, nextView) {
      state.vars.currentView = nextView
    },
    changeIsPageHidden (state, hidden) {
      state.vars.isPageHidden = hidden
    },
    readJwtFromStorage (state) {
      readJwtFromStorage(state)
    },
    selectJwt (state, jwt) {
      vm.$socket.close()
      state.vars.userId = undefined
      state.vars.currentConversationId = undefined
      state.vars.jwt = jwt
      state.vars.isLoading = true
      vm.$socket.open()
    },
    selectSidebarTab (state, tab) {
      state.vars.currentSidebarTab = tab
    },
    selectConversation (state, conversation) {
      state.vars.currentConversationId = conversation.id
    },
    updateState (state, change) {
      updateStateRoutine(state, change)
    }
  },
  actions: {
    async guestLogin ({ state, commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_SERVER_GUESTLOGIN_URL, cors(), {
          headers: { 'Cache-Control': 'no-cache' }
        })
        let jwt = res.data.jwt
        window.localStorage.setItem(process.env.VUE_APP_SITE_JWT_KEY, jwt)
        Cookie.set(process.env.VUE_APP_SITE_COOKIE_KEY, jwt, { path: process.env.VUE_APP_SITE_COOKIE_PATH })
        commit('selectJwt', jwt)
      } catch (error) {
        // console.log(error.message)
        state.arrays.errors.push(error)
        commit('changeView', 'error')
        state.vars.isLoading = false
      }
    },
    async getChatMetaUpdates ({ state, commit, getters }) {
      if (getters.chatOffline || !state.vars.jwt) {
        try {
          let res = await axios.get(process.env.VUE_APP_SERVER_GETCHATMETAUPDATES_URL, cors(), {
            headers: { 'Cache-Control': 'no-cache' }
          })
          let change = res.data
          if (!state.vars.isConnectedOrConnecting) {
            commit('updateState', change)
          }
        } catch (error) {
          // console.log(error.message)
        }
        state.vars.chatInitialized = true
        if (!state.vars.jwt) {
          state.vars.isLoading = false
        }
      } else if (!getters.chatOffline && state.vars.jwt) {
        vm.$socket.open()
      }
    },
    redirectToLogin ({ state, commit }) {
      state.vars.isLoading = true
      state.arrays.errors = []
      window.localStorage.removeItem(process.env.VUE_APP_SITE_JWT_KEY)
      Cookie.remove(process.env.VUE_APP_SITE_COOKIE_KEY, { path: process.env.VUE_APP_SITE_COOKIE_PATH })
      window.location.href = process.env.VUE_APP_SITE_LOGINREDIRECT_URL
    },
    async selectContact ({ state, commit, dispatch }, contact) {
      if (state.vars.userId === undefined) {
        await dispatch('guestLogin')
        await until(_ => state.vars.userId !== undefined)
      }

      let conversation = this.getters.getActiveConversationByContact(contact)
      if (conversation == null) {
        dispatch('createConversation', contact)
      } else {
        commit('selectSidebarTab', 'ConversationList')
        commit('selectConversation', conversation)
      }
    },
    sendMessage ({ state, commit }, { conversationId, text }) {
      let data = {
        conversationId,
        text,
        ts: moment()
      }
      vm.$socket.emit('new_message_sent', data)
    },
    sendReadMessage ({ state, commit }, messageId) {
      let data = {
        messageId,
        readAt: moment()
      }
      debouncedSendReadMessages(data)
    },
    createConversation ({ state, commit }, contact) {
      let data = {
        createdAt: moment(),
        users: [state.vars.userId, contact.id]
      }
      vm.$socket.emit('new_conversation_sent', data)
    },
    closeConversation ({ state, commit }, conversationId) {
      let data = {
        id: conversationId,
        closedAt: moment()
      }
      vm.$socket.emit('close_conversation_sent', data)
    },
    hideConversation ({ state, commit }) {
      state.vars.currentConversationId = undefined
    },
    socket_initialize_config ({ state, commit }, change) {
      commit('updateState', change)
    },
    socket_initialize_lobbies ({ state, commit }, change) {
      commit('updateState', change)
    },
    socket_contact_connection_changed ({ state, commit }, change) {
      commit('updateState', change)
    },
    socket_contact_active_conversations_changed ({ state, commit }, change) {
      commit('updateState', change)
    },
    socket_new_message_received ({ state, getters, commit }, change) {
      commit('updateState', change)
      let newMessage = change.models.messages.data[0]
      let conversation = change.models.conversations.data[0]
      if (
        state.vars.isPageHidden &&
        getters.privileges.canReceiveNotificationsWhenPageUnfocused &&
        newMessage.sender.id !== state.vars.userId
      ) {
        let user = getters.userById(newMessage.sender.id)
        vm.$notification.show(
          `New message from ${getters.getUserDisplayName(user)}`,
          {
            icon: process.env.VUE_APP_SITE_NOTIFICATIONICON_URL,
            body: `${getters.getUserDisplayName(user)} sent a new message: "${newMessage.text}"`
          },
          {
            onclick: function () {
              commit('selectSidebarTab', 'ConversationList')
              commit('selectConversation', conversation)
            }
          }
        )
      }
    },
    socket_new_conversation_received ({ state, getters, commit }, change) {
      commit('updateState', change)
      let newConversation = change.models.conversations.data[0]
      if (newConversation.createdBy.id === state.vars.userId) {
        commit('selectSidebarTab', 'ConversationList')
        commit('selectConversation', newConversation)
      }
      if (
        state.vars.isPageHidden &&
        getters.privileges.canReceiveNotificationsWhenPageUnfocused &&
        newConversation.createdBy.id !== state.vars.userId
      ) {
        let user = getters.userById(newConversation.createdBy.id)
        vm.$notification.show(
          `New conversation from ${getters.getUserDisplayName(user)}`,
          {
            icon: process.env.VUE_APP_SITE_NOTIFICATIONICON_URL,
            body: `${getters.getUserDisplayName(user)} created a new conversation with you`
          },
          {
            onclick: function () {
              commit('selectSidebarTab', 'ConversationList')
              commit('selectConversation', newConversation)
            }
          }
        )
      }
    },
    socket_close_conversation_received ({ state, commit, getters }, change) {
      commit('updateState', change)
      let tab = getters.privileges.canSelectConversation ? 'ConversationList' : 'ContactList'
      state.vars.currentSidebarTab = tab
    },
    socket_read_messages_received ({ state, commit }, change) {
      commit('updateState', change)
    },
    socket_initialize_user ({ state, commit, getters }, change) {
      state.vars.currentConversationId = undefined
      commit('updateState', change)
      commit('changeView', 'chat')
      let tab
      if (getters.privileges.canSelectConversation && getters.privileges.canSelectContact) {
        tab = state.models.conversations.length > 0 ? 'ConversationList' : 'ContactList'
      } else if (getters.privileges.canSelectConversation) {
        tab = 'ConversationList'
      } else {
        tab = state.models.conversations.some(
          c => c.completedAt !== null && intersection(c.users.map(u => u.id), state.arrays.contactsIds).length > 0
        )
          ? 'ConversationList'
          : 'ContactList'
      }
      state.vars.currentSidebarTab = tab
      state.vars.isLoading = false
      if (getters.privileges.canReceiveNotificationsWhenPageUnfocused) {
        vm.$notification.requestPermission()
      }
      vm.$socket.emit('initialize_user_finished')
    },
    socket_connect ({ state }) {
      state.vars.isConnectedOrConnecting = true
      readJwtFromStorage(state)
      state.vars.isLoading = true
      if (state.vars.jwt) {
        vm.$socket.emit('authentication', { jwt: state.vars.jwt })
      }
    },
    socket_disconnect ({ state }) {
      state.vars.isConnectedOrConnecting = false
      state.vars.isLoading = false
    },
    socket_authenticated ({ state }) {},
    socket_unauthorized ({ state, commit }, error) {
      if (error.name === 'AuthenticationError') {
        // console.log(error.message)
        state.arrays.errors.push(error)
        commit('changeView', 'error')
      }
      state.vars.isLoading = false
    }
  },
  getters: {
    lobbiesToSubscribeTo: (state, getters) => {
      if (state.vars.userId) {
        let account = state.models.accounts.find(i => i.id === state.vars.userId)
        return account ? getters.lobbyById(account.lobby).lobbiesToSubscribeTo : undefined
      }
      return getters.lobbyById(state.vars.config.getChatMetaUpdatesAsLobby).lobbiesToSubscribeTo
    },
    strings: (state, getters) => {
      if (state.vars.userId) {
        let account = state.models.accounts.find(i => i.id === state.vars.userId)
        return account ? getters.lobbyById(account.lobby).strings : undefined
      }
      return getters.lobbyById(state.vars.config.getChatMetaUpdatesAsLobby).strings
    },
    privileges: (state, getters) => {
      if (state.vars.userId) {
        let account = state.models.accounts.find(i => i.id === state.vars.userId)
        return account ? getters.lobbyById(account.lobby).privileges : undefined
      }
      return getters.lobbyById(state.vars.config.getChatMetaUpdatesAsLobby).privileges
    },
    chatOffline: (state, getters) => {
      return !state.vars.userId && getters.contacts.length === 0
    },
    getNumActiveConversationsByUserId: state => id => {
      let userActiveConversation = state.models.usersActiveConversations.find(i => i.id === id)
      return userActiveConversation ? userActiveConversation.numActiveConversations : undefined
    },
    lobbyById: (state, getters) => id => {
      let lobby = state.vars.lobbies.find(i => i.id === id)
      return lobby
    },
    lobbiesByIds: (state, getters) => ids => {
      return ids.map(id => getters.lobbyById(id))
    },
    userById: state => id => {
      let account = state.models.accounts.find(i => i.id === id)
      let person = account.person ? state.models.persons.find(i => i.id === account.person.id) : null
      let user = { ...account }
      user.person = person
      return user
    },
    usersByIds: (state, getters) => ids => {
      return ids.map(id => getters.userById(id))
    },
    conversationById: (state, getters) => id => {
      let conversation = state.models.conversations.find(i => i.id === id)
      let messages = getters.messagesByIds(conversation.messages.map(i => i.id))
      let users = getters.usersByIds(conversation.users.map(i => i.id))
      let createdBy = getters.userById(conversation.createdBy.id)
      let closedBy = conversation.closedBy ? getters.userById(conversation.closedBy.id) : null

      let result = { ...conversation }
      result.messages = messages
      result.users = users
      result.createdBy = createdBy
      result.closedBy = closedBy
      return result
    },
    conversationsByIds: (state, getters) => ids => {
      return ids.map(id => getters.conversationById(id))
    },
    messageById: (state, getters) => id => {
      let message = state.models.messages.find(i => i.id === id)
      let sender = getters.userById(message.sender.id)

      let result = { ...message }
      result.sender = sender
      return result
    },
    messagesByIds: (state, getters) => ids => {
      return ids.map(id => getters.messageById(id))
    },
    contacts: (state, getters) => {
      return getters.usersByIds(state.arrays.contactsIds)
    },
    conversations: (state, getters) => {
      return getters.conversationsByIds(state.models.conversations.map(i => i.id))
    },
    getUserDisplayName: () => user => {
      if (!user.person) {
        return user.username
      } else if (user.status.id === 2 && user.id.toString() === user.username) {
        return `Guest#${user.id}`
      } else {
        return `${user.person.firstName} ${user.person.lastName}`
      }
    },
    getUserAvatar: () => user => {
      const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i')

      let personId = idx(user, _ => _.person.id);
      let photo = idx(user, _ => _.person.photo) || process.env.VUE_APP_SITE_BLANKAVATAR_URL
      return absoluteUrlRegex.test(photo) ? photo : `${process.env.VUE_APP_SITE_PHOTODIRECTORY_URL}/${personId}/${photo}`
    },
    getActiveConversationByContact: state => contact => {
      return state.models.conversations.find(
        conversation =>
          conversation.closedAt == null &&
          conversation.users.length === 2 &&
          conversation.users.some(user => user.id === contact.id)
      )
    }
  }
})

let readJwtFromStorage = function (state) {
  if (!state.vars.jwt) {
    let localstorageJwt = window.localStorage.getItem(process.env.VUE_APP_SITE_JWT_KEY)
    if (localstorageJwt) {
      state.vars.jwt = localstorageJwt
      // console.log('jwt found in localStorage, trying to authenticate')
    } else {
      let cookieJwt = Cookie.get(process.env.VUE_APP_SITE_COOKIE_KEY)
      if (cookieJwt) {
        state.vars.jwt = cookieJwt
        // console.log('jwt found in cookie, trying to authenticate')
      }
    }
  }
}

let sendReadMessages = function (data) {
  vm.$socket.emit('read_messages_sent', data)
}
let debouncedSendReadMessages = debounceQueue(sendReadMessages, 200)

let updateStateRoutine = function (state, change) {
  let func
  for (let model in change.models) {
    switch (change.models[model].mode) {
      case 'set':
        func = (model, elements) => elements
        break
      case 'update':
        func = (model, elements) => {
          for (let element of elements) {
            let index = model.findIndex(i => i.id === element.id)
            if (index !== -1) {
              model.splice(index, 1, element)
            } else {
              model.push(element)
            }
          }
          return [...model]
        }
        break
      case 'add':
        func = (model, elements) => [...model, ...elements]
        break
      case 'remove':
        func = (model, elements) => model.filter(element => !elements.includes(element))
        break
      default:
        throw new Error(`State change mode '${change.models[model].mode}' not allowed for 'models'`)
    }
    state.models[model] = func(state.models[model], change.models[model].data)
  }

  for (let array in change.arrays) {
    switch (change.arrays[array].mode) {
      case 'set':
        func = (array, elements) => elements
        break
      case 'update':
        func = (model, elements) => {
          for (let element of elements) {
            let index = model.findIndex(i => i.id === element.id)
            if (index !== -1) {
              model.splice(index, 1, element)
            } else {
              model.push(element)
            }
          }
          return [...model]
        }
        break
      case 'add':
        func = (array, elements) => [...array, ...elements]
        break
      case 'remove':
        func = (array, elements) => array.filter(element => !elements.includes(element))
        break
      default:
        throw new Error(`State change mode '${change.arrays[array].mode}' not allowed for 'arrays'`)
    }
    state.arrays[array] = func(state.arrays[array], change.arrays[array].data)
  }

  for (let variable in change.vars) {
    switch (change.vars[variable].mode) {
      case 'set':
        func = variable => (state.vars[variable] = change.vars[variable].data)
        break
      case 'delete':
        func = variable => state.vars.delete(variable)
        break
      default:
        throw new Error(`State change mode '${change.vars[variable].mode}' not allowed for 'vars'`)
    }
    func(variable)
  }
}
