<template>
  <li class="contact" :class="{ active: isActive }" @click="selectConversation(conversation)">
    <div class="wrap">
      <div
        class="contact-profile"
        v-tooltip="{ content: getUserDisplayName(partner), boundariesElement: 'html', placement: 'right' }"
      >
        <span class="contact-status" :class="partner.status"></span>
        <img :src="getUserAvatar(partner)" />
      </div>
      <div class="meta">
        <p class="name">{{ getUserDisplayName(partner) }}</p>
        <template v-if="lastMessage">
          <p class="preview">
            <font-awesome-icon :icon="lastMessageIcon" :class="lastMessageClass" />
            {{ lastMessage.text }}
          </p>
        </template>
      </div>
      <div class="meta-extra">
        <p class="timestamp" v-if="lastMessage">{{ lastUpdateTimestampFromNow }}</p>
        <span
          v-if="unreadMessages.length > 0"
          v-tooltip="{ content: unreadMessagesText, boundariesElement: 'html', placement: 'right' }"
          class="unread-messages"
        >{{ unreadMessages.length }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import moment from 'moment'

import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ConversationListItem',
  props: ['conversation'],
  data: () => ({
    lastUpdateTimestampFromNow: null
  }),
  computed: {
    partner () {
      return this.partners[0]
    },
    partners () {
      return this.conversation.users.filter(user => user.id !== this.userId)
    },
    isActive () {
      return this.currentConversationId === this.conversation.id
    },
    lastMessage () {
      return this.conversation.messages.slice(-1)[0]
    },
    lastMessageClass () {
      return this.lastMessage.sender.id === this.userId ? 'sent' : 'replies'
    },
    lastMessageIcon () {
      return this.lastMessage.sender.id === this.userId ? 'arrow-right' : 'arrow-left'
    },
    unreadMessages () {
      return this.conversation.messages.filter(
        m => m.sender.id !== this.userId && !m.readBy.map(rb => rb.id).includes(this.userId)
      )
    },
    unreadMessagesText () {
      return `${this.unreadMessages.length} unread messages`
    },
    ...mapState({
      currentConversationId: state => state.vars.currentConversationId,
      userId: state => state.vars.userId
    }),
    ...mapGetters(['getUserAvatar', 'getUserDisplayName'])
  },
  methods: {
    getLastUpdateTimestampFromNow () {
      let timestamp = this.lastMessage ? this.lastMessage.ts : this.conversation.createdAt
      this.lastUpdateTimestampFromNow = moment(timestamp).format('LT')
    },
    ...mapMutations(['selectConversation'])
  },
  created () {
    this.getLastUpdateTimestampFromNow()
    setInterval(this.getLastUpdateTimestampFromNow, 1000)
  },
  beforeDestroy () {
    clearInterval(this.getLastUpdateTimestampFromNow)
  }
}
</script>

<style lang="scss" scoped></style>
