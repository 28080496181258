<template>
  <fragment>
    <div
      id="frame"
      v-bind:class="{ 'loggedin': userId, 'conversationOpen': currentConversationId }"
      ref="frame"
      v-visibility-change="visibilityChange"
    >
      <component
        :is="currentView"
        v-if="!chatOffline || hasErrors"
        @start-loading="startLoading"
        @stop-loading="stopLoading"
      />
      <div v-else-if="chatOffline && !isLoading" class="login">
        <h1>Sorry, we are offline</h1>
        <p>
          Contact us at
          <a href="mailto:hr@allprofiles.it">hr@allprofiles.it</a>
        </p>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import Chat from './views/Chat'
import Login from './views/Login'
import Error from './views/Error'

export default {
  name: 'app',
  data: () => ({
    loading: null,
    chatMetaUpdates: null
  }),
  components: {
    Login,
    Chat,
    Error
  },
  views: {
    login: Login,
    chat: Chat,
    error: Error
  },
  computed: {
    currentComponent () {
      return this.$options.views[this.currentView]
    },
    ...mapState({
      currentView: state => state.vars.currentView,
      chatInitialized: state => state.vars.chatInitialized,
      isLoading: state => state.vars.isLoading,
      hasErrors: state => state.arrays.errors.length > 0,
      userId: state => state.vars.userId,
      currentConversationId: state => state.vars.currentConversationId
    }),
    ...mapGetters(['chatOffline'])
  },
  methods: {
    startLoading () {
      this.loading = this.$loading.show({
        container: this.fullPage ? null : this.$refs.frame
      })
    },
    stopLoading () {
      if (this.loading) {
        this.loading.hide()
        this.loading = null
      }
    },
    visibilityChange (evt, hidden) {
      this.changeIsPageHidden(hidden)
    },
    ...mapMutations(['changeIsPageHidden', 'readJwtFromStorage']),
    ...mapActions(['getChatMetaUpdates'])
  },
  watch: {
    '$store.state.vars.isLoading': function () {
      if (this.isLoading) {
        this.startLoading()
      } else {
        this.stopLoading()
      }
    },
    '$store.state.vars.chatInitialized': function () {
      if (this.chatInitialized) {
        this.$socket.open()
      }
    },
    chatOffline () {
      if (!this.chatOffline) {
        this.$socket.open()
      }
    },
    '$store.state.vars.userId': function () {
      if (this.$store.state.vars.userId) {
        this.$socket.open()
        clearTimeout(this.chatMetaUpdates)
      }
    }
  },
  mounted () {
    this.startLoading()
    this.readJwtFromStorage()
    this.getChatMetaUpdates()
    this.chatMetaUpdates = setInterval(() => {
      this.getChatMetaUpdates()
    }, 5000)
  }
}
</script>

<style lang="scss">
$white: #f5f5f5;
$backgroundDark: #00304f;
$backgroundLighter: #1f4d7a;
$primaryDark: #d2691e;
$primaryLighter: #d67632;
$primaryLight: #e29f6f;
$primaryAccent: #fec012;
$secondaryDark: #335d86;
$secondaryLight: #476d92;
$secondaryLightest: #708daa;
$secondaryAccent1: #06a77d;
$secondaryAccent2: #cc0000;
$border: $secondaryLight;
$red: #e74c3c;
$green: #2ecc71;
$yellow: #f1c40f;
$gray: #95a5a6;
$break: 700px;
$break-big: 900px;

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.vb > .vb-dragger > .vb-dragger-styler {
  background-color: $primaryLighter;
}

.messages.vb > .vb-dragger > .vb-dragger-styler {
  background-color: $primaryAccent;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 4px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  width: 8px;
  margin-left: -4px;
  height: calc(100%);
}

.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  z-index: 999;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: #fefefe;
    color: black;
    border-radius: 8px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #fefefe;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: black;

    .popover-inner {
      background: $color;
      color: #fefefe;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(#fefefe, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

#frame {
  font-size: 16px;
  color: $white;
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  min-height: 300px;
  max-height: 400px;
  background: $backgroundDark;
  display: flex;
  position: relative;
  border: 1px solid $border;
  @media screen and (max-width: 360px) {
    width: 100%;
    height: 100vh;
  }

  &.loggedin {
    height: 92vh;
    max-height: 650px;
  }

  .alert {
    padding: 8px;
    border-radius: 4px;
    border-left: 4px solid black;
    margin: 0 auto;

    &--success {
      background: #c3f3d7;
      border-left-color: #2ed573;
      color: #23ad5c;
    }

    &--danger {
      background: #ffe0e3;
      border-left-color: #ff4757;
      color: #ff4757;
    }

    &--warning {
      background: #ffdb9b;
      border-left-color: #ffa502;
      color: #ce8500;
    }

    &--info {
      background: #d7f0ff;
      border-left-color: #71c9ff;
      color: #3eb6ff;
    }

    &--dark {
      background: #4d4d4d;
      border-left-color: #333;
      color: #ccc;
    }
  }

  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }
  li {
    list-style: none;
  }

  .logo {
    background: $backgroundDark;
    border-bottom: 1px solid $border;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;

    img {
      max-width: 100%;
    }
  }

  #sidepanel {
    display: flex;
    flex-direction: column;
    min-width: 140px;
    max-width: 300px;
    width: 100%;
    height: auto;
    background: $backgroundLighter;
    border-right: 1px solid $border;
    flex-shrink: 1;
    color: $white;

    #guest-alert {
      border-top: 1px solid $border;
      text-align: center;
      font-weight: bold;

      .alert {
        border-radius: 0;
      }
    }

    #sidebar-tablist {
      display: flex;

      button {
        border: none;
        width: 100%;
        padding: 10px 0;
        background: $secondaryLight;
        color: $white;
        cursor: pointer;
        font-size: 0.85em;

        svg {
          margin-right: 6px;
        }

        &:focus {
          outline: none;
        }

        &:nth-child(1) {
          border-right: 1px solid $backgroundLighter;
        }

        &:hover {
          background: $secondaryLightest;
        }

        i {
          margin-right: 3px;
          font-size: 1em;
        }
      }
    }
  }

  #profile {
    width: 100%;
    border-bottom: 1px solid $border;

    .wrap {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(0.3s height ease);

      img {
        width: 50px;
        border-radius: 50%;
        padding: 3px;
        height: auto;
        flex-shrink: 0;
        @include transition(0.3s border ease);

        &.online {
          border: 2px solid $green;
        }

        &.away {
          border: 2px solid $yellow;
        }

        &.busy {
          border: 2px solid $red;
        }

        &.offline {
          border: 2px solid $gray;
        }
      }

      p {
        margin-left: 15px;
      }
    }
  }

  #chat-meta,
  #contacts,
  #conversations {
    height: calc(100%);

    h2 {
      padding: 0 6px;
      font-size: 1.5em;
    }

    h3 {
      padding: 0 6px;
      font-size: 1.2em;
    }

    & > div > p,
    & > ul > p {
      padding: 6px;
    }
  }

  #chat-meta {
    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      li.contact {
        position: relative;
        padding: 10px 0 15px 0;
        font-size: 0.9em;

        .contact-profile {
          display: flex;
          flex-shrink: 0;
        }

        .wrap {
          display: flex;
          justify-content: space-between;
          position: relative;
          margin: 0 10px 0px 15px;

          span {
            position: absolute;
            left: 0;
            margin: -2px 0 0 -2px;
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.online {
              border: 2px solid $primaryDark;
              background: $green;
            }

            &.away {
              border: 2px solid $primaryDark;
              background: $yellow;
            }

            &.busy {
              border: 2px solid $primaryDark;
              background: $red;
            }

            &.offline {
              border: 2px solid $primaryDark;
              background: $gray;
            }
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .meta {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            .name {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  #contacts,
  #conversations {
    ul {
      li.contact {
        position: relative;
        padding: 10px 0 15px 0;
        font-size: 0.9em;
        cursor: pointer;

        &.readonly {
          cursor: auto;

          &:hover {
            background: $backgroundLighter;
          }
        }

        &:hover {
          background: $secondaryLight;
        }

        &.active {
          background: $secondaryLight;
          border-left: 5px solid $secondaryLightest;

          span.contact-status {
            display: none;
            border: 2px solid $secondaryLight !important;
          }

          .wrap {
            margin: 0 5px 0 10px;
          }
        }

        .contact-profile {
          display: flex;
          flex-shrink: 0;
        }

        .wrap {
          display: flex;
          justify-content: space-between;
          position: relative;
          margin: 0 10px 0px 15px;

          span {
            position: absolute;
            left: 0;
            margin: -2px 0 0 -2px;
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.online {
              border: 2px solid $primaryDark;
              background: $green;
            }

            &.away {
              border: 2px solid $primaryDark;
              background: $yellow;
            }

            &.busy {
              border: 2px solid $primaryDark;
              background: $red;
            }

            &.offline {
              border: 2px solid $primaryDark;
              background: $gray;
            }
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .meta-extra {
            padding: 0 10px;
            flex-shrink: 0;

            .unread-messages {
              margin: 0 auto;
              margin-top: 4px;
              position: relative;
              background: $secondaryAccent2;
              width: 17px;
              height: 17px;
              display: block;
              text-align: center;
              font-size: 0.8em;
            }

            .timestamp {
              text-align: right;
            }
          }

          .meta {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            .name {
              font-weight: 600;
            }

            .preview {
              font-weight: 400;
              min-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex-grow: 0;
              flex-shrink: 1;
              @include transition(1s all ease);

              svg.sent {
                color: $secondaryAccent1;
              }

              svg.replies {
                color: $secondaryAccent2;
              }

              span {
                position: initial;
                border-radius: initial;
                background: none;
                border: none;
                padding: 0 2px 0 0;
                margin: 0 0 0 1px;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }

  .login,
  .error,
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .conversation-profile {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      background: $backgroundLighter;
      height: 90px;
      border-bottom: 1px solid $border;

      .contact-profile {
        display: flex;

        img {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 0 10px 0 10px;
        }

        p {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .menu {
        button {
          margin-left: 10px;
          border: none;
          padding: 12px 6px;
          cursor: pointer;
          background: $secondaryLight;
          color: $white;

          svg {
            margin-right: 6px;
          }

          &:nth-last-child(1) {
            margin-right: 20px;
          }

          &:disabled {
            background: $gray;
          }

          &:enabled {
            &:hover {
              background: $secondaryLightest;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .messages {
      height: 100%;

      ul {
        .alert-big {
          text-align: center;
          width: 80%;
          margin: 20px auto;
          padding: 16px;
          border-radius: 4px;
          background: $primaryLighter;
          color: $white;
        }
        .alert-small {
          font-size: 0.8em;
          text-align: center;
          background: $secondaryLight;
          width: 92%;
          margin: 10px auto;
          padding: 6px;
          color: $white;
        }

        li {
          display: flex;
          margin: 15px 15px 5px 15px;
          width: calc(100% - 25px);
          font-size: 0.9em;

          &:nth-last-child(1) {
            margin-bottom: 20px;
          }

          &.replies {
            img {
              width: 35px;
              height: 35px;
              margin: 2px 12px 0 0;
              flex-shrink: 0;
            }

            p {
              background: $primaryDark;
              color: $white;
              position: relative;

              &:after {
                right: 100%;
                top: 20px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-right-color: $primaryDark;
                border-width: 6px;
                margin-top: -6px;
              }
            }
          }

          &.sent {
            flex-direction: row-reverse;

            img {
              width: 35px;
              height: 35px;
              margin: 6px 0 0 12px;
              flex-shrink: 0;
            }

            p {
              background: $primaryAccent;
              position: relative;
              color: black;

              &:after {
                left: 100%;
                top: 20px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-left-color: $primaryAccent;
                border-width: 6px;
                margin-top: -6px;
              }
            }
          }

          img {
            width: 22px;
            border-radius: 50%;
          }

          p {
            display: inline-block;
            padding: 10px 15px;
            border-radius: 10px;
            line-height: 130%;
            white-space: pre-wrap;
            word-break: break-all;
          }
        }
      }
    }

    .message-input {
      width: 100%;
      z-index: 99;
      border-top: 1px solid $border;

      .wrap {
        position: relative;
        display: flex;

        textarea {
          box-sizing: border-box;
          min-height: 79px;
          max-height: 120px;
          overflow-x: hidden;
          resize: none;
          border: none;
          width: 100%;
          font-size: 0.9em;
          color: $backgroundDark;
          margin: 0;
          padding: 12px 12px 4px 12px;

          &:focus {
            outline: none;
          }
        }

        button {
          border: none;
          width: 50px;
          padding: 12px 0;
          cursor: pointer;
          background: $secondaryDark;
          color: $white;

          &:disabled {
            background: $gray;
          }

          &:enabled {
            &:hover {
              background: $secondaryLight;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .login,
  .error {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

    h1 {
      padding: 0 6px;
      font-size: 2em;
    }

    p {
      padding: 6px;
    }

    .message {
      margin: 0 auto;
      width: 90%;
    }

    & > button {
      margin: 0 auto;
      border: none;
      padding: 12px;
      font-size: 1em;
      cursor: pointer;
      background: $secondaryLight;
      color: $white;

      svg {
        margin-right: 6px;
      }

      &:disabled {
        background: $gray;
      }

      &:enabled {
        &:hover {
          background: $secondaryLight;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  #login-view,
  #error-view {
    min-width: 140px;
    max-width: 300px;
    max-height: 500px;
  }

  .login,
  .error,
  .content {
    @media screen and (max-width: $break) {
      width: calc(100%);
      min-width: 200px !important;
    }
    @media screen and (min-width: $break-big) {
      width: calc(100%);
    }

    .conversation-profile {
      .menu {
        button {
          @media screen and (max-width: $break) {
            padding: 6px;
          }
        }
      }
    }
  }
}

#frame.conversationOpen {
  #sidepanel {
    @media screen and (max-width: $break) {
      width: 58px;
      min-width: 58px;
      flex-shrink: 0;
    }

    #sidebar-tablist {
      @media screen and (max-width: $break) {
        flex-direction: column;
      }

      button {
        &:nth-child(1) {
          @media screen and (max-width: $break) {
            border-right: none;
            border-bottom: 1px solid $backgroundLighter;
          }
        }

        i {
          @media screen and (max-width: $break) {
            font-size: 1.3em;
          }
        }

        span {
          @media screen and (max-width: $break) {
            display: none;
          }
        }
      }
    }
  }

  #profile {
    @media screen and (max-width: $break) {
      width: 100%;
      margin: 0 auto;
      background: $secondaryDark;
    }

    .wrap {
      @media screen and (max-width: $break) {
        height: 55px;
      }

      p {
        @media screen and (max-width: $break) {
          display: none;
        }
      }
    }
  }

  #chat-meta,
  #contacts,
  #conversations {
    h2 {
      @media screen and (max-width: $break) {
        display: none;
      }
    }

    h3 {
      @media screen and (max-width: $break) {
        display: none;
      }
    }

    & > div > p {
      @media screen and (max-width: $break) {
        display: none;
      }
    }

    & > ul > p {
      @media screen and (max-width: $break) {
        display: none;
      }
    }

    .accordion__arrow {
      @media screen and (max-width: $break) {
        display: none !important;
      }
    }

    .accordion__header {
      @media screen and (max-width: $break) {
        display: none !important;
      }
    }

    .accordion__item {
      @media screen and (max-width: $break) {
        display: block !important;
      }
    }
  }

  #contacts,
  #conversations {
    ul {
      li.contact {
        @media screen and (max-width: $break) {
          padding: 10px 0 15px 0;
        }

        &.active {
          .wrap {
            @media screen and (max-width: $break) {
              margin: 0 0 0 5px;
            }
          }
        }

        .wrap {
          @media screen and (max-width: $break) {
            width: 100%;
            margin: 0 0 0 10px;
          }

          img {
            @media screen and (max-width: $break) {
              margin-right: 0px;
            }
          }

          .meta-extra {
            .timestamp {
              @media screen and (max-width: $break) {
                display: none;
              }
            }
          }

          .meta {
            @media screen and (max-width: $break) {
              display: none;
            }
          }
        }
      }
    }
  }

  .login,
  .error {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

    & > button {
      @media screen and (max-width: $break) {
        padding: 6px;
      }
    }
  }
}
</style>
