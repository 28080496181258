<template>
  <div id="contacts" v-bar>
    <ul>
      <h2>{{ strings.contactListLabel }}</h2>
      <p v-if="sortedContacts.length > 0">{{ strings.contactListHeaderText }}</p>
      <p v-else>{{ strings.contactListHeaderEmptyText }}</p>

      <template v-if="showAccordion">
        <BaseAccordionItem
          v-for="lobbyContacts in filteredContactsByLobbyName"
          v-bind:key="lobbyContacts.lobbyName"
        >
          <template v-slot:header>
            <h3>{{ lobbyContacts.lobbyName }} &nbsp; ({{ lobbyContacts.contacts.length }})</h3>
          </template>
          <template v-slot:content>
            <ContactListItem
              v-for="contact in lobbyContacts.contacts"
              v-bind:key="contact.id"
              :contact="contact"
            />
          </template>
        </BaseAccordionItem>
      </template>
      <template v-else>
        <h3>{{ strings.contactListLabel }} &nbsp; ({{ sortedContacts.length }})</h3>
        <ContactListItem
          v-for="contact in sortedContacts"
          v-bind:key="contact.id"
          :contact="contact"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import BaseAccordionItem from './Base/BaseAccordionItem'
import ContactListItem from './ContactListItem'

export default {
  name: 'ContactList',
  computed: {
    sortedContacts () {
      let sortFunction = contact => contact.username
      return orderBy(this.contacts, sortFunction, ['desc'])
    },
    filteredContactsByLobbyName () {
      let lobbiesByName = groupBy(this.lobbies, lobby => lobby.strings.lobbyName)
      return map(lobbiesByName, (value, key) => ({
        lobbyName: key,
        lobby: value,
        contacts: this.sortedContacts.filter(contact => contact.lobby === value.id)
      })).filter(i => i.contacts.length > 0)
    },
    showAccordion () {
      return this.filteredContactsByLobbyName.length > 1
    },
    ...mapGetters(['contacts', 'strings', 'lobbiesToSubscribeTo']),
    ...mapState({
      lobbies: state => state.vars.lobbies
    })
  },
  components: {
    BaseAccordionItem,
    ContactListItem
  }
}
</script>

<style lang="scss" scoped></style>
